import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  typography: {
    lineHeight: 1,
    fontFamily: 'League Spartan',
    //fontSize: 16,
    htmlFontSize: 10.65,
    h1: {
      //fontSize: '4.5rem'
      fontSize: '3.1rem'
    },
    h2: {
      //fontSize: '3rem',
      fontSize: '2.65rem'
    },
    h3: {
      //fontSize: '2.3rem'
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.75rem'
    },
    body2: {
      fontSize: '1.35rem'
    },
    caption: {
      fontSize: '1rem'
    }
  },
  palette: {
    primary: {
      main: '#FC0000',
    },
    /*secondary: {
      main: green[500],
    },*/
    cream: {
      main: '#FFFBF5'
    },
    dark: {
      main: '#2C0000'
    },
    white: {
      main: '#FFF',
      contrastText: '#2C0000'
    },
    text: {
      primary: '#2C0000',
      secondary: '#8B7F7F'
    },
    error: {
      main: '#FC0000'
    },
    medium: {
      main: '#FA5252',
      contrastText: '#fff'
    },
    info: {
      main: '#1C7ED6'
    },
    yellow: {
      main: '#FCC419'
    },
    easy: {
      main: '#89D886',
      contrastText: '#fff'
    },
    hard: {
      main: '#2C0000',
      contrastText: '#fff'
    },
    divider: '#2C0000'
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&:not(.MuiCollapse-entered)::before': {
            content: '" "',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '10vh',
            background: 'transparent linear-gradient(0deg, #FFFBF5 0%, #FFFBF500 100%) 0% 0% no-repeat padding-box'
          }
        }
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 251, 245, .85)'
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2rem',
          backgroundColor: '#fff'
        },
        notchedOutline: {
          borderWidth: 2,
          borderColor: '#EAEAE3',
          '& legend': {
            marginLeft: 25
          }
        }
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          maxHeight: 60,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'currentColor',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: 'translate(40px, -9px) scale(0.75) !important',
        },
        outlined: {
          '&.icon': {
            transform: 'translate(75px, 1.1rem)',
          }
        }
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        filled: {
          //transform: 'none',
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
          //minHeight: 60,
        },
        contained: {
          borderRadius: '2rem'
        },
        sizeSmall: {
          fontSize: '1rem !important'
        },
        sizeLarge: {
          fontSize: '1.5rem !important'
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#FC0000'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 15,
          overflow: 'hidden'
        },
        elevation1: {
          boxShadow: '0px 3px 6px #00000029'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.3,
          '& p:not(.MuiTypography-root)': {
            margin: 0
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: '0 10px'
        },
        label: {
          fontWeight: 'bold',
          fontSize: '1.5rem'
        },
        labelSmall: {
          fontSize: '1.25rem'
        },
        colorWhite: {
          boxShadow: '0px 3px 6px #0000001A'
        },
        outlined: {
          border: '2px solid #270301'
        },
        deleteIcon: {
          color: 'inherit'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '& .MuiRating-pristine': {
            padding: 0
          }
        }
      }
    }  
  },
}));

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
};

export default theme;