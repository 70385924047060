import React from 'react';
import { useAuth } from './auth';
import OneSignal from 'react-onesignal';

export const useNotifications = () => {
	
	const { user } = useAuth();
	const [initialized, setInitialized] = React.useState(false);

	const initialize = async () => {
		await OneSignal.init({
			appId: process.env.REACT_APP_ONESIGNAL_APP_ID
		});
		setInitialized(true);
		OneSignal.showSlidedownPrompt();
	}

	React.useEffect(() => {
		if(Boolean(process.env.REACT_APP_ONESIGNAL_APP_ID)) {
			initialize();
		}
	}, []);

	React.useEffect(() => {
		if(initialized) {
			if(Boolean(user)) {
			  OneSignal.setExternalUserId(user.id);
			} else {
			  OneSignal.removeExternalUserId();
			}
		}
	}, [initialized, user]);

	return {initialized};
};