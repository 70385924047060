import { configureStore } from '@reduxjs/toolkit';
import { api } from '../services/api';
import { recipes } from '../services/recipes';
import appReducer from './slices/app';
import layoutReducer from './slices/layout';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appReducer,
    layout: layoutReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(api.middleware)
    .concat(recipes.middleware),
});