import { api } from './api';

export const recipes = api.injectEndpoints({
  endpoints: (builder) => ({
    getRecipe: builder.query({
      query: (slug) => ({
        url: `/v1/recipes/slug:${slug}`,
      }),
      transformResponse: (response) => response.result,
    }),
    getRandomRecipe: builder.query({
      query: () => ({
        url: `/v1/recipes-random?ts=${Date.now()}`,
      }),
      transformResponse: (response) => response.result,
    }),
    getRecipes: builder.query({
      query: (filters) => ({
        url: `/v1/recipes`,
        params: filters
      }),
      transformResponse: (response) => response.result,
    }),
    getRelatedRecipes: builder.query({
      query: (request) => ({
        url: `/v1/recipes/${request.id}/related`,
        params: request.body
      }),
      transformResponse: (response) => response.result,
    }),
    getCategories: builder.query({
      query: (filters) => ({
        url: `/v1/categories`
      }),
      transformResponse: (response) => response.result,
    }),
    getDiets: builder.query({
      query: (filters) => ({
        url: `/v1/diets`
      }),
      transformResponse: (response) => response.result,
    }),
    rate: builder.mutation({
      query: (request) => ({
        url: `/v1/recipes/${request.id}/rate`,
        method: "PUT",
        body: {
          rating: request.value
        },
      }),
      transformResponse: (response) => response.result,
    }),
    bookmark: builder.mutation({
      query: (request) => ({
        url: `/v1/recipes/${request.id}/favorite`,
        method: "PUT",
        body: {
          favorite: request.value
        },
      }),
      transformResponse: (response) => response.result,
    }),
    autocomplete: builder.query({
      query: (keyword) => ({
        url: `/v1/autocomplete-recipes`,
        params: {
          q: keyword
        }
      }),
      transformResponse: (response) => response.result,
    }),
    getCategory: builder.query({
      query: (slug) => ({
        url: `/v1/categories/slug:${slug}`
      }),
      transformResponse: (response) => response.result,
    }),
    getIngredient: builder.query({
      query: (slug) => ({
        url: `/v1/ingredients/slug:${slug}`
      }),
      transformResponse: (response) => response.result,
    }),
    getChef: builder.query({
      query: (slug) => ({
        url: `/v1/chefs/slug:${slug}`
      }),
      transformResponse: (response) => response.result,
    }),
    getDiet: builder.query({
      query: (slug) => ({
        url: `/v1/diets/slug:${slug}`
      }),
      transformResponse: (response) => response.result,
    }),
    getFavoriteRecipes: builder.query({
      query: (slug) => ({
        url: "/v1/favorite-recipes",
      }),
      transformResponse: (response) => response.result,
    }),
  })
});

export const { 
  useGetRelatedRecipesQuery,
  useGetRecipeQuery,
  useGetRandomRecipeQuery,
  useGetRecipesQuery,
  useRateMutation,
  useBookmarkMutation,
  useAutocompleteQuery,
  useGetCategoryQuery,
  useGetCategoriesQuery,
  useGetIngredientQuery,
  useGetChefQuery,
  useGetDietQuery,
  useGetDietsQuery,
  useGetFavoriteRecipesQuery
} = recipes;