import React, { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { useAuth } from './hooks/auth';

const Home = lazy(() => import("./routes/home/Index"));
const Auth = lazy(() => import("./routes/auth/Index"));
const User = lazy(() => import("./routes/user/Index"));
const Verify = lazy(() => import("./routes/user/Verify"));
const Profile = lazy(() => import("./routes/user/Profile"));
const Favorites = lazy(() => import("./routes/user/Favorites"));
const Avatars = lazy(() => import("./routes/user/Avatars"));
const Welcome = lazy(() => import("./routes/auth/Welcome"));
const Recipe = lazy(() => import("./routes/recipes/Recipe"));
const Recipes = lazy(() => import("./routes/recipes/Index"));
const Category = lazy(() => import("./routes/recipes/Index"));
const Newsletter = lazy(() => import("./routes/newsletter/Index"));
const Applications = lazy(() => import("./routes/applications/Index"));
const Info = lazy(() => import("./routes/info/Index"));
const Academia = lazy(() => import("./routes/info/Academia"));
const NotFound = lazy(() => import("./routes/errors/NotFound"));
const Maintenance = lazy(() => import("./routes/errors/Maintenance"));
const Password = lazy(() => import("./routes/auth/Password"));

const AuthRoute = (props) => {
  const { unauth, children } = props;
  const {auth} = useAuth();
  const location = useLocation();
  if (!unauth && !auth) {
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }
  if (unauth && auth) {
    return <Navigate to="/home" state={{ from: location }} />;
  }
  return children;
};

const Router = () => (
  <Suspense fallback={<LinearProgress />}>
    <Routes>
      <Route 
        path="*" 
        element={<NotFound />} 
      />
      <Route 
        path="/maintenance" 
        element={<Maintenance />} 
      />
      <Route 
        path="/" 
        element={<Home />} 
      />
      <Route 
        path="/ricette/:slug" 
        element={<Recipe />} 
      />
      <Route 
        path="/ricette" 
        element={<Recipes />} 
      />
      <Route 
        path="/categorie/:slugCategory" 
        element={<Recipes />} 
      />
      <Route 
        path="/ingredienti/:slugIngredient" 
        element={<Recipes />} 
      />
      <Route 
        path="/chef/:slugChef" 
        element={<Recipes />} 
      />
      <Route 
        path="/diete/:slugDiet" 
        element={<Recipes />} 
      />
      <Route 
        path="/auth/:type" 
        element={<Auth />} 
      />
      <Route 
        path="/services/password-reset" 
        element={<Password />} 
      />
      <Route 
        path="/services/mail-validation" 
        element={<Verify />} 
      />
      <Route 
        path="/user" 
        element={
          <AuthRoute>
            <User />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/profile" 
        element={
          <AuthRoute>
            <Profile />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/favorites" 
        element={
          <AuthRoute>
            <Favorites />
          </AuthRoute>
        } 
      />
      <Route 
        path="/user/avatar" 
        element={
          <AuthRoute>
            <Avatars />
          </AuthRoute>
        } 
      />
      <Route 
        path="/welcome" 
        element={
          <AuthRoute>
            <Welcome />
          </AuthRoute>
        } 
      />
      <Route 
        path="/newsletter" 
        element={<Newsletter />} 
      />
      <Route 
        path="/lavora-con-noi" 
        element={<Applications />} 
      />
      <Route 
        path="/chi-siamo" 
        element={<Info />} 
      />
      <Route 
        path="/academia" 
        element={<Academia />} 
      />
    </Routes>
  </Suspense>
);

export default Router;