import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: localStorage.hasOwnProperty('x-token') && localStorage.hasOwnProperty('x-refresh-token') ? {
    auth_token: localStorage.getItem('x-token'),
    refresh_token: localStorage.getItem('x-refresh-token')
  } : false,
  config: {
    client_id: localStorage.getItem('x-client-id')
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    saveAuth: (state, action) => {
      localStorage.setItem('x-token', action.payload.auth_token);
      localStorage.setItem('x-refresh-token', action.payload.refresh_token);
      state.auth = action.payload;
    },
    mergeAuth: (state, action) => {
      if(action.payload.auth_token) {
        localStorage.setItem('x-token', action.payload.auth_token);
      }
      if(action.payload.refresh_token) {
        localStorage.setItem('x-refresh-token', action.payload.refresh_token);
      }
      state.auth = {
        ...state.auth,
        ...action.payload
      };
    },
    saveConfig: (state, action) => {
      localStorage.setItem('x-client-id', action.payload.client_id);
      state.config = action.payload;
    },
    mergeConfig: (state, action) => {
      if(action.payload.client_id) {
        localStorage.setItem('x-client-id', action.payload.client_id);
      }
      state.config = {
        ...state.config,
        ...action.payload
      };
    },
    exit: (state) => {
      localStorage.removeItem('x-token');
      localStorage.removeItem('x-refresh-token');
      state.auth = false;
    }
  },
})

export const { saveAuth, mergeAuth, saveConfig, mergeConfig, exit } = appSlice.actions;

export default appSlice.reducer;

export const selectAuth = (state) => state.app.auth;
export const selectConfig = (state) => state.app.config;