import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { osName, osVersion } from 'react-device-detect';
import { Mutex } from 'async-mutex';
import history from '../utils/history';
import { saveConfig, mergeAuth, exit } from '../store/slices/app';
import { showError } from '../store/slices/layout';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASEURL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().app.auth.auth_token;
    const clientId = getState().app.config.client_id;

    headers.set("X-Platform", process.env.REACT_APP_PLATFORM);
    headers.set("X-App-Name", process.env.REACT_APP_NAME);
    headers.set("X-App-Version", process.env.REACT_APP_VERSION);
    headers.set("X-OS-Name", osName);
    headers.set("X-OS-Version", osVersion);
    if(token) {
      headers.set("X-Auth-Token", token);
    }
    if(clientId) {
      headers.set("X-Client-ID", clientId);
    }
    return headers;
  },
});

const baseQueryWithClientManagement = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    const data = result.error.data;
    if (result.error.status === 400) {
      if (data && (data.error_code === "BAD-CLIENT" || (data.error_code === "BAD-HEADERS" && !api.getState().app.config.client_id))) {
        const registerResult = await baseQuery(
          { url: "/v1/startup", method: "GET" },
          api,
          extraOptions
        );
        if(registerResult.data) {
          if(registerResult.data.result.blocked) {
            history.push('/maintenance');
          } else {
            api.dispatch(saveConfig(registerResult.data.result));
            result = await baseQuery(args, api, extraOptions);
          }
        }
      } else {
        api.dispatch(showError(data));
      }
    } else if (result.error.status === 401) {
      api.dispatch(exit());
      history.push('/auth/login', {from: history.location.pathname});
    } else if (result.error.status === 403) {
      if (data && (data.error_code === "EXPIRED-AUTH-TOKEN" || data.error_code === "BAD-AUTH-TOKEN") && api.getState().app.auth.refresh_token) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const registerResult = await baseQuery(
              { 
                url: "/v1/auth/refresh", 
                method: "POST",
                body: {
                  refresh_token: api.getState().app.auth.refresh_token
                }  
              },
              api,
              extraOptions
            );
            if(registerResult.data) {
              api.dispatch(mergeAuth(registerResult.data.result));
              result = await baseQuery(args, api, extraOptions);
            }
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await baseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(showError(data));
      }
    } else if (result.error.status === 503) {
      if (data && data.error_code === "MAINTENANCE-MODE") {
        history.push('/maintenance');
      }
    } else {
      api.dispatch(showError(data));
    }
  }
  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithClientManagement,
  endpoints: (builder) => ({
    startup: builder.query({
      query: () => ({
        url: `/v1/startup`,
      }),
      transformResponse: (response) => response.result,
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/v1/auth/signin/email",
        method: "POST",
        body: credentials,
      }),
      transformResponse: (response) => response.result,
    }),
    verify: builder.mutation({
      query: (request) => ({
        url: "/v1/auth/email-validation",
        method: "PUT",
        body: request,
      }),
      transformResponse: (response) => response,
    }),
    socialLogin: builder.mutation({
      query: (request) => ({
        url: `/v1/auth/signin/${request.social}`,
        method: "POST",
        body: request.body,
      }),
      transformResponse: (response) => response.result,
    }),
    register: builder.mutation({
      query: (request) => ({
        url: "/v1/auth/signup/email",
        method: "POST",
        body: request,
      }),
      transformResponse: (response) => response.result,
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/v1/auth/signout",
        method: "POST",
      }),
      transformResponse: (response) => response.result
    }),
    getMe: builder.query({
      query: () => ({
        url: `/v1/me`,
      }),
      transformResponse: (response) => response.result,
    }),
    passwordRequest: builder.mutation({
      query: (request) => ({
        url: "/v1/auth/password-reset/step-1",
        method: "PUT",
        body: request,
      }),
      transformResponse: (response) => response.result,
    }),
    passwordSave: builder.mutation({
      query: (request) => ({
        url: "/v1/auth/password-reset/step-2",
        method: "PUT",
        body: request,
      }),
      transformResponse: (response) => response.result,
    }),
    getPage: builder.query({
      query: (slug) => ({
        url: `/v1/pages/slug:${slug}`,
      }),
      transformResponse: (response) => response.result,
    }),
    getSeo: builder.query({
      query: (request) => ({
        url: "/v1/seo",
        params: request
      }),
      transformResponse: (response) => response.result,
    }),
    saveProfile: builder.mutation({
      query: (request) => ({
        url: "/v1/me",
        method: "PUT",
        body: request,
      }),
      transformResponse: (response) => response.result,
    }),
    deleteProfile: builder.mutation({
      query: () => ({
        url: "/v1/me",
        method: "DELETE"
      }),
      transformResponse: (response) => response.result,
    }),
    getAvatars: builder.query({
      query: () => ({
        url: "/v1/avatars"
      }),
      transformResponse: (response) => response.result,
    }),
    newsletter: builder.mutation({
      query: (request) => ({
        url: "/v1/newsletter/signup",
        method: "POST",
        body: request,
      }),
      transformResponse: (response) => response.result,
    }),
    getApplications: builder.query({
      query: () => ({
        url: "/v1/job-positions"
      }),
      transformResponse: (response) => response.result,
    }),
  }),
});

export const {
  useStartupQuery,
  useLoginMutation,
  useVerifyMutation,
  useSocialLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
  useGetMeQuery,
  usePasswordRequestMutation,
  usePasswordSaveMutation,
  useGetPageQuery,
  useGetSeoQuery,
  useSaveProfileMutation,
  useDeleteProfileMutation,
  useGetAvatarsQuery,
  useNewsletterMutation,
  useGetApplicationsQuery
} = api;
