import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNotifications } from './hooks/notifications';
import Router from "./Router";

function App() {

  const location = useLocation();
  const { initialized: loadedNotifications } = useNotifications();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Router />
  );
}

export default App;
