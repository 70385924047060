import React from "react";
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMeQuery } from '../services/api';
import { selectAuth, mergeAuth, exit, saveAuth } from '../store/slices/app';

export const useAuth = () => {
  
  const dispatch = useDispatch();
  const selectedAuth = useSelector(selectAuth);
  const authData = useMemo(() => (selectedAuth ? selectedAuth : false), [selectedAuth]);
  const auth = useMemo(() => (selectedAuth ? true : false), [selectedAuth]);
  const user = useMemo(() => (selectedAuth ? selectedAuth.user : false), [selectedAuth]);
  const { data: userData, isSuccess, isFetching: isFetchingUser } = useGetMeQuery(undefined, {skip: !auth || (auth && user)});

  const setAuth = (data) => {
    dispatch(saveAuth(data))
  }

  const setUser = (data) => {
    dispatch(mergeAuth({user: data}))
  }

  const logout = () => {
    dispatch(exit());
  }

  React.useEffect(() => {
    if(isSuccess) {
      setUser(userData)
    }
  }, [isSuccess, userData])

  return {auth, authData, setAuth, user, isFetchingUser, setUser, logout};
}
