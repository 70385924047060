import React from 'react';
import "moment/locale/it";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { store } from './store/store';
import theme from './config/theme';
import history from './utils/history';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import './index.css';

moment.locale("it");
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <LocalizationProvider 
              dateAdapter={AdapterMoment} 
              adapterLocale={moment.locale()}
            >
              <App />
            </LocalizationProvider>
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </HistoryRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
